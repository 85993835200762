import type React from 'react'

import classNames from 'classnames'
import NextImage from 'next/image'

export interface HeroBannerMediaProps {
  /**
   * File source.
   */
  src: string
  /**
   * Alt.
   */
  alt: string
  /**
   * Custom style.
   */
  className?: string
  /**
   * Height that should be applied.
   */
  height?: number
  /**
   * Width that should be applied.
   */
  width?: number
}

export const HeroBannerMedia: React.FC<HeroBannerMediaProps> = ({
  src,
  alt,
  className,
  height = 0,
  width = 0,
}) => {
  return (
    <NextImage
      alt={alt}
      className={classNames(
        { 'flex-1': width === 0 || height === 0 },
        className,
      )}
      height={height}
      sizes="100vw"
      src={src}
      width={width}
      priority
    />
  )
}

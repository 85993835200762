import type { FC, PropsWithChildren, ReactElement } from 'react'
import React, { Children, cloneElement } from 'react'

import { Step } from './Step/Step'

import type { StepProps } from './Step/Step'

interface SubElements {
  Step: typeof Step
}

export const OrderedList: FC<PropsWithChildren> & SubElements = ({
  children,
}) => {
  const steps = Children.toArray(children)
  return (
    <ol>
      {React.Children.map(steps, (child, index) =>
        cloneElement(child as ReactElement<StepProps>, {
          index: index + 1,
          isLast: index === steps.length - 1,
        }),
      )}
    </ol>
  )
}
OrderedList.Step = Step

import type { FC, PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface StepProps {
  index?: number
  title: string
  isLast?: boolean
}
export const Step: FC<PropsWithChildren<StepProps>> = ({
  children,
  title,
  index,
  isLast,
}) => {
  return (
    <li>
      <div className="flex items-start justify-start gap-x-4">
        <div
          className="flex h-8 w-8 items-center justify-center rounded-full border border-black-200 p-2 text-neutral-subtle"
          data-testid={`step-index-${index}`}
        >
          {index}
        </div>
        <h4 className="text-xl text-neutral-strong font-circular-medium">
          {title}
        </h4>
      </div>
      <div
        className={classNames('my-2 ml-4 pl-8 space-y-4 pb-4 [&>*]:pb-0', {
          'border-l border-black-50': !isLast,
        })}
        data-testid={`step-separator-${index}`}
      >
        {children}
      </div>
    </li>
  )
}

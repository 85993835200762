import useTranslation from 'next-translate/useTranslation'

import { CWTooltip } from '../ComponentsWeb'

export interface GlossaryProps {
  term: string
}
export const Glossary: React.FC<GlossaryProps> = ({ term }) => {
  const { t } = useTranslation('content')

  const definition = t(term)
  const anchorId = term.replaceAll(' ', '-').toLowerCase()

  if (definition === term) {
    return term
  }

  return (
    <CWTooltip anchorId={anchorId} content={definition}>
      <span className="cursor-help underline decoration-blue-600 hover:decoration-blue-400">
        {term}
      </span>
    </CWTooltip>
  )
}

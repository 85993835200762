'use client'

import { useLayoutEffect, useState } from 'react'

import { calculateOffset } from '@shared/utils'

export const SCROLL_INDICATED_CONTENT_WRAPPER_ID = 'indicate.scroll'

export const ScrollIndicator: React.FC = (props) => {
  const [progress, setProgress] = useState(0)

  useLayoutEffect(() => {
    const handleScroll = () =>
      setProgress(
        calculateProgress(calculateOffset(SCROLL_INDICATED_CONTENT_WRAPPER_ID)),
      )

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="w-full h-2 bg-code">
      <div
        className="h-2 bg-bold-accent-purple-gradient transform transition-width duration-200"
        style={{ width: `${progress}%` }}
        {...props}
      />
    </div>
  )
}

/**
 * Helper function to calculate the reading progress based off of the scroll position.
 */
export const calculateProgress = (offset: number = 0) => {
  const scrollPosition = document.documentElement.scrollTop

  /**
   * Calculate the max distance that can be scrolled.
   */
  const height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight -
    offset

  return height === 0 ? 0 : Math.floor((scrollPosition / height) * 100)
}

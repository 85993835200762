import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

import { HeroBannerContent } from './HeroBanner.Content/HeroBanner.Content'
import { HeroBannerMedia } from './HeroBanner.Media/HeroBanner.Media'

interface SubComponents {
  Media: typeof HeroBannerMedia
  Content: typeof HeroBannerContent
}

export interface HeroBannerProps extends PropsWithChildren {
  /**
   * Custom style.
   */
  className?: string
}

export const HeroBanner: React.FC<HeroBannerProps> & SubComponents = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'bg-hero-background flex items-center w-full',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

HeroBanner.Media = HeroBannerMedia
HeroBanner.Content = HeroBannerContent

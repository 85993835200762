import type React from 'react'
import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface OverviewCardTitleProps extends PropsWithChildren {
  /**
   * Title text size.
   */
  size?: 'md' | 'lg'
  /**
   * Custom style.
   */
  className?: string
}

export const OverviewCardTitle: React.FC<OverviewCardTitleProps> = ({
  size = 'md',
  className,
  children,
}) => {
  return (
    <h4
      className={classNames(
        'text-neutral-strong',
        { 'type-body-base-bold': size === 'md' },
        { 'type-h-title-md': size === 'lg' },
        className,
      )}
    >
      {children}
    </h4>
  )
}

'use client'
import type { FC, PropsWithChildren } from 'react'
import { useCallback } from 'react'

import { Button } from '@circlefin/components/lib/Button'
import { Icon } from '@circlefin/components/lib/Icon'
import { ModalLink } from '@circlefin/modal-router'
import { SearchInputField } from '@features/algolia.components/src/SearchInputField'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import { useNavigation } from '@features/navigation.hooks'
import { routes } from '@features/playground.constants'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '../Link/Link'
import { NotificationBanner } from '../NotificationBanner'

import { Logo } from './Logo'
import { ScrollIndicator } from './ScrollIndicator/ScrollIndicator'

interface HeaderProps extends PropsWithChildren {
  /**
   * Is the scroll indicator enabled.
   */
  enableScrollIndicator?: boolean
  /**
   * Title of the content.
   */
  title?: string
}

export const Header: FC<HeaderProps> = ({
  title,
  enableScrollIndicator = true,
}) => {
  const { t } = useTranslation('common')
  const [showNavigation, setShowNavigation] = useNavigation()
  const migrationEnabled = useFeatureFlag('migrationEnabled')

  const handleNavigationToggle = useCallback(() => {
    setShowNavigation((prev) => !prev)
  }, [setShowNavigation])

  return (
    <div className="fixed top-0 z-30 w-full border-b border-neutral-subtle bg-white">
      <NotificationBanner />
      <div className="w-full flex items-center px-6 py-5 h-20 gap-x-8">
        {title ? (
          <>
            <div
              className={classNames('md:block', {
                hidden: !showNavigation,
              })}
            >
              <Logo />
            </div>
            <p
              className={classNames(
                'md:hidden type-body-base-bold',
                { hidden: showNavigation },
                { block: !showNavigation },
              )}
            >
              {title}
            </p>
          </>
        ) : (
          <Logo />
        )}
        <div className="hidden md:block">
          <SearchInputField />
        </div>
        <div className="hidden md:flex md:items-center md:justify-end md:space-x-2 flex-1">
          {migrationEnabled && (
            <ModalLink pathname={routes.interactivePlayground}>
              <Button
                className="normal-case"
                label={t('header.links.apiPlayground')}
                size="base"
                variant="text"
              >
                {t('header.links.apiPlayground')}
              </Button>
            </ModalLink>
          )}
          <Link path="/" type="faucet">
            <Button
              className="normal-case"
              label={t('header.links.faucet')}
              size="base"
              variant="text"
            >
              {t('header.links.faucet')}
            </Button>
          </Link>
          <Link
            path="/release-notes/w3s-2024"
            title={t('header.links.releaseNotes')}
            type="internal"
          >
            <Button
              className="normal-case"
              label={t('header.links.releaseNotes')}
              size="base"
              variant="text"
            >
              {t('header.links.releaseNotes')}
            </Button>
          </Link>
          <Link
            path="https://support.usdc.circle.com/hc/en-us/p/contactus"
            title={t('header.links.help')}
            type="external"
          >
            <Button
              className="normal-case"
              label={t('header.links.help')}
              size="base"
              variant="text"
            >
              {t('header.links.help')}
            </Button>
          </Link>
        </div>
        <div className="flex md:hidden justify-end space-x-2 flex-1">
          <Button onClick={handleNavigationToggle} size="sm" variant="text">
            {showNavigation ? (
              <Icon name="XSolid" size={18} />
            ) : (
              <Icon
                className="text-neutral-strong"
                name="MenuAlt2Outline"
                size={18}
              />
            )}
          </Button>
        </div>
      </div>
      {enableScrollIndicator ? <ScrollIndicator /> : null}
    </div>
  )
}

'use client'

import { controller } from '@circlefin/one-trust/core'
import { useNavigation } from '@features/navigation.hooks'
import classNames from 'classnames'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '../Link/Link'
import { TextLink } from '../TextLink/TextLink'

export const Footer: React.FC = () => {
  const { t } = useTranslation('common')
  const [showNavigation] = useNavigation()
  return (
    <div
      className={classNames(
        'z-30 flex flex-col text-center pb-4 pt-8 text-xs md:px-12 bg-white border-t border-neutral-subtle lg:text-start',
        {
          hidden: showNavigation,
        },
      )}
    >
      <div className="text-neutral-subtle">
        {t('footer.copyright', { currentDate: new Date().getFullYear() })}
      </div>
      <div className="mt-2 flex flex-row flex-wrap items-center justify-center space-x-1 lg:space-x-4 lg:items-start lg:justify-normal lg:mt-4">
        <Link
          className="text-center text-interactive"
          path="/legal/developer-terms"
          type="console"
        >
          {t('footer.links.developerTerms')}
        </Link>
        <Link path="/legal/service-terms" type="console">
          {t('footer.links.serviceTerms')}
        </Link>
        <Link
          path="https://www.circle.com/en/legal/privacy-policy"
          type="external"
        >
          {t('footer.links.privacyPolicy')}
        </Link>
        <Link path="/legal/acceptable-use-policy" type="console">
          {t('footer.links.acceptableUse')}
        </Link>
        <TextLink
          href="#"
          onClick={controller.toggleInfoDisplay}
          size="xs"
          variant="primary"
        >
          {t`footer.links.privacyPolicyChoice`}
          <Image
            alt={t`footer.links.privacyPolicyChoice`}
            className="inline self-center"
            height={30}
            src="/images/PrivacyOptions.svg"
            width={30}
          />
        </TextLink>
        <Link
          className="text-center text-interactive no-underline hover:underline"
          path="https://www.circle.com/en/legal/cookie-policy?_gl=1*1xaj7mu*_ga*NjQyMTQ1ODkuMTY5MzU5MzE0OA..*_ga_GJDVPCQNRV*MTY5Mzk5NDg3MS4yMC4xLjE2OTM5OTYwNDEuNTUuMC4w"
          type="external"
        >
          {t('footer.links.cookiePolicy')}
        </Link>
      </div>
    </div>
  )
}

import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

import { Feature } from './Feature/Feature'
interface Elements {
  Feature: typeof Feature
}
interface FeaturesProps extends PropsWithChildren {
  variant: 'grid' | 'list'
  background: 'light' | 'blue'
}
export const Features: React.FC<FeaturesProps> & Elements = ({
  children,
  variant = 'grid',
  background = 'blue',
}) => {
  return (
    <div className="w-full py-2">
      <div
        className={classNames(
          'border border-neutral-subtle p-6 grid grid-cols-1 rounded-lg gap-6',
          {
            'bg-primary lg:grid-cols-2': variant === 'grid',
            'bg-primary': background === 'blue',
            'bg-white': background === 'light',
          },
        )}
        data-testid="features-background"
      >
        {children}
      </div>
    </div>
  )
}
Features.Feature = Feature

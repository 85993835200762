import type React from 'react'
import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface OverviewCardCTAProps extends PropsWithChildren {
  /**
   * Custom style.
   */
  className?: string
}

export const OverviewCardCTA: React.FC<OverviewCardCTAProps> = ({
  className,
  children,
}) => {
  return (
    <p
      className={classNames(
        'type-body-base-bold text-interactive w-fit whitespace-nowrap flex flex-row items-center hover:underline hover:cursor-pointer',
        className,
      )}
    >
      {children}
    </p>
  )
}

import type React from 'react'
import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface OverviewCardDescriptionProps extends PropsWithChildren {
  /**
   * Custom style.
   */
  className?: string
}

export const OverviewCardDescription: React.FC<
  OverviewCardDescriptionProps
> = ({ className, children }) => {
  return (
    <p
      className={classNames('grow type-body-sm text-neutral-subtle', className)}
    >
      {children}
    </p>
  )
}

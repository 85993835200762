import type { FC, PropsWithChildren } from 'react'

import { CWIcon } from '../../ComponentsWeb'
import { Link, type LinkProps } from '../../Link/Link'

export interface TitleProps extends PropsWithChildren {
  /**
   * Configuration of the link that appears next to the title.
   * If no configuration is provided, the icon will not be rendered.
   */
  link?: LinkProps
}

export const FeatureTitle: FC<TitleProps> = ({ children, link }) => {
  return (
    <strong className="-mt-px mb-1 flex items-center text-base text-neutral-strong font-circular-bold">
      {children}
      {link ? (
        <Link {...link} className="ml-2">
          <CWIcon className="size-4" name="ExternalLinkOutline" />
        </Link>
      ) : null}
    </strong>
  )
}

import type React from 'react'

import { CWIcon, CWIconCircle } from '../ComponentsWeb'
import {
  OverviewCard,
  type OverviewCardProps,
} from '../OverviewCard/OverviewCard'

import type { IconProps } from '@circlefin/components/lib/Icon'

export interface ListCardProps extends Omit<OverviewCardProps, 'children'> {
  /**
   * Icon name.
   */
  icon: IconProps['name']
  /**
   * Card title.
   */
  title: string
  /**
   * Card description.
   */
  description: string
}

export const ListCard: React.FC<ListCardProps> = ({
  icon,
  title,
  description,
  ...cardProps
}) => (
  <OverviewCard
    className="flex flex-row items-center py-4 px-6"
    padding="none"
    {...cardProps}
  >
    <CWIconCircle
      className="shrink-0 self-start mr-3"
      intent="info"
      name={icon}
      size="small"
    />
    <div className="flex-1 mr-4">
      <OverviewCard.Title>{title}</OverviewCard.Title>
      <OverviewCard.Description>{description}</OverviewCard.Description>
    </div>
    <CWIcon
      className="p-2 text-icon-neutral shrink-0"
      name="ArrowRightOutline"
      size={40}
    />
  </OverviewCard>
)

'use client'

import { useCallback, useEffect, useMemo } from 'react'

import { Checkbox, MultiSelectDropdown } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { SubmitUsdcAccessDocument } from '@features/common.graphql'
import { useMutation } from '@shared/apollo'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { CWIcon } from '../ComponentsWeb'
import { Link } from '../Link/Link'

import type { CheckboxValue } from '@circlefin/components/lib/Checkbox/Checkbox.Group.Context/context'
import type { MultiSelectDropdownItem } from '@circlefin/components/lib/MultiSelectDropdown'
import type { SubmitHandler } from '@circlefin/form/useForm/useForm'
import type { Translate } from 'next-translate'

const schema = y.object().shape({
  firstName: y.string().required(),
  lastName: y.string().required(),
  businessName: y.string().required(),
  businessEmail: y.string().email().required(),
  countriesOfOperation: y.array().of(y.string().required()).min(1).required(),
  importanceForUsers: y.array().of(y.string().required()).min(1).required(),
  endCustomer: y.array().of(y.string().required()).min(1).required(),
  stoppingYouToday: y.array().of(y.string().required()).min(1).required(),
  userBaseSize: y.string().required(),
  expectedMonthlyVolume: y.string().required(),
  formFactor: y.array().of(y.string().required()).min(1).required(),
  questions: y.string(),
})

type Schema = y.InferType<typeof schema>

export const UsdcAccessForm: React.FC = () => {
  const { t } = useTranslation('common')

  const [submitUsdcAccess, { loading, data }] = useMutation(
    SubmitUsdcAccessDocument,
    {
      onError: () => {},
    },
  )

  const [Form, { watch, setValue, formState, trigger }] = useForm({
    schema,
    mode: 'onBlur',
  })

  const formVariables: Partial<Schema> = watch()

  const fieldItems = useMemo(() => {
    return {
      countriesOfOperation: getItemsFromLocales(t, 'countriesOfOperationItems'),
      importanceForUsers: getItemsFromLocales(t, 'importanceForUsersItems'),
      endCustomer: getItemsFromLocales(t, 'endCustomerItems'),
      stoppingYouToday: getItemsFromLocales(t, 'stoppingYouTodayItems'),
      userBaseSize: getItemsFromLocales(t, 'userBaseSizeItems'),
      expectedMonthlyVolume: getItemsFromLocales(
        t,
        'expectedMonthlyVolumeItems',
      ),
      formFactor: getItemsFromLocales(t, 'formFactorItems'),
    }
  }, [t])

  const selectedItems = useMemo(() => {
    return {
      countriesOfOperation: fieldItems.countriesOfOperation.filter((item) =>
        formVariables.countriesOfOperation?.includes(item.value),
      ),
      importanceForUsers: fieldItems.importanceForUsers.filter((item) =>
        formVariables.importanceForUsers?.includes(item.value),
      ),
      endCustomer: fieldItems.endCustomer
        .filter((item) => formVariables.endCustomer?.includes(item.value))
        .map((item) => item.value),
      stoppingYouToday: fieldItems.stoppingYouToday.filter((item) =>
        formVariables.stoppingYouToday?.includes(item.value),
      ),
      formFactor: fieldItems.formFactor
        .filter((item) => formVariables.formFactor?.includes(item.value))
        .map((item) => item.value),
    }
  }, [fieldItems, formVariables])

  useEffect(() => {
    if (formVariables.endCustomer !== undefined) void trigger('endCustomer')
  }, [trigger, formVariables.endCustomer])

  useEffect(() => {
    if (formVariables.formFactor !== undefined) void trigger('formFactor')
  }, [trigger, formVariables.formFactor])

  const handleChangeMultiSelect = useCallback(
    (identifier: keyof (typeof schema)['fields']) =>
      (items: MultiSelectDropdownItem<string>[]) => {
        setValue(
          identifier,
          items
            .filter(
              (
                item,
              ): item is MultiSelectDropdownItem<string> & { value: string } =>
                item.value !== undefined,
            )
            .map((item) => item.value),
        )
      },
    [setValue],
  )

  const handleChangeCheckboxGroup = useCallback(
    (identifier: keyof (typeof schema)['fields']) =>
      (value: Array<CheckboxValue>) => {
        setValue(identifier, value as string[])
      },
    [setValue],
  )

  const handleBlur = useCallback(
    (identifier: keyof (typeof schema)['fields']) => () =>
      void trigger(identifier),
    [trigger],
  )

  const handleSubmit: SubmitHandler<Schema> = useCallback(
    async (data: Schema) => {
      await submitUsdcAccess({ variables: { input: data } })
    },
    [submitUsdcAccess],
  )

  const requiredErrorMessage = useMemo(
    () => (
      <p className="type-body-xs text-error mt-1 flex items-center gap-1">
        <CWIcon name="ExclamationCircleSolid" />
        {t`common:forms.validation.required`}
      </p>
    ),
    [t],
  )

  if (data) {
    return <SuccessMessage />
  }

  return (
    <Form data-testid="usdc-access-form" onSubmit={handleSubmit}>
      <div className="space-y-4">
        <div className="flex flex-row gap-4">
          <Form.Input
            className="w-full"
            data-testid="usdc-access-form-first-name"
            label={getLabel(t`forms.usdcAccessForm.firstName`)}
            name="firstName"
            placeholder={t`forms.usdcAccessForm.firstName`}
            type="text"
          />

          <Form.Input
            className="w-full"
            data-testid="usdc-access-form-last-name"
            label={getLabel(t`forms.usdcAccessForm.lastName`)}
            name="lastName"
            placeholder={t`forms.usdcAccessForm.lastName`}
            type="text"
          />
        </div>

        <div className="flex flex-row gap-4">
          <Form.Input
            className="w-full"
            data-testid="usdc-access-form-business-name"
            label={getLabel(t`forms.usdcAccessForm.businessName`)}
            name="businessName"
            placeholder={t`forms.usdcAccessForm.businessName`}
            type="text"
          />
          <Form.Input
            className="w-full"
            data-testid="usdc-access-form-business-email"
            label={getLabel(t`forms.usdcAccessForm.businessEmail`)}
            name="businessEmail"
            placeholder={t`forms.usdcAccessForm.businessEmail`}
            type="text"
          />
        </div>

        <div>
          <MultiSelectDropdown
            className="w-full"
            data-testid="usdc-access-form-countries-of-operation"
            items={fieldItems.countriesOfOperation}
            label={getLabel(t`forms.usdcAccessForm.countriesOfOperation`)}
            name="countriesOfOperation"
            onBlur={handleBlur('countriesOfOperation')}
            onChange={handleChangeMultiSelect('countriesOfOperation')}
            placeholder={t`forms.usdcAccessForm.placeholders.selectAll`}
            selectedItems={selectedItems.countriesOfOperation}
          >
            <MultiSelectDropdown.Options
              items={fieldItems.countriesOfOperation}
            />
          </MultiSelectDropdown>

          {formState.errors.countriesOfOperation && requiredErrorMessage}
        </div>

        <div>
          <MultiSelectDropdown
            className="w-full"
            data-testid="usdc-access-form-importance-for-users"
            items={fieldItems.importanceForUsers}
            label={getLabel(t`forms.usdcAccessForm.importanceForUsers`)}
            name="importanceForUsers"
            onBlur={handleBlur('importanceForUsers')}
            onChange={handleChangeMultiSelect('importanceForUsers')}
            placeholder={t`forms.usdcAccessForm.placeholders.selectAll`}
            selectedItems={selectedItems.importanceForUsers}
          >
            <MultiSelectDropdown.Options
              items={fieldItems.importanceForUsers}
            />
          </MultiSelectDropdown>

          {formState.errors.importanceForUsers && requiredErrorMessage}
        </div>

        <div>
          <Checkbox.Group
            className="[&_.input-layout.row]:flex-col [&_.input-layout.row]:gap-1 [&_.label]:mb-1"
            data-testid="usdc-access-form-end-customer"
            label={getLabel(t`forms.usdcAccessForm.endCustomer`)}
            name="endCustomer"
            onChange={handleChangeCheckboxGroup('endCustomer')}
            value={selectedItems.endCustomer}
          >
            {fieldItems.endCustomer.map((field) => (
              <Checkbox key={field.label} {...field} />
            ))}
          </Checkbox.Group>

          {formState.errors.endCustomer && requiredErrorMessage}
        </div>

        <div>
          <MultiSelectDropdown
            className="w-full"
            data-testid="usdc-access-form-stopping-you-today"
            items={fieldItems.stoppingYouToday}
            label={getLabel(t`forms.usdcAccessForm.stoppingYouToday`)}
            name="stoppingYouToday"
            onBlur={handleBlur('stoppingYouToday')}
            onChange={handleChangeMultiSelect('stoppingYouToday')}
            placeholder={t`forms.usdcAccessForm.placeholders.selectAll`}
            selectedItems={selectedItems.stoppingYouToday}
          >
            <MultiSelectDropdown.Options items={fieldItems.stoppingYouToday} />
          </MultiSelectDropdown>

          {formState.errors.stoppingYouToday && requiredErrorMessage}
        </div>

        <Form.Dropdown
          className="w-full"
          data-testid="usdc-access-form-user-base-size"
          items={fieldItems.userBaseSize}
          label={getLabel(t`forms.usdcAccessForm.userBaseSize`)}
          name="userBaseSize"
          onBlur={handleBlur('userBaseSize')}
          placeholder={t`forms.usdcAccessForm.placeholders.selectOption`}
        />

        <Form.Dropdown
          className="w-full"
          data-testid="usdc-access-form-expected-monthly-volume"
          items={fieldItems.expectedMonthlyVolume}
          label={getLabel(t`forms.usdcAccessForm.expectedMonthlyVolume`)}
          name="expectedMonthlyVolume"
          onBlur={handleBlur('expectedMonthlyVolume')}
          placeholder={t`forms.usdcAccessForm.placeholders.selectOption`}
        />

        <div>
          <Checkbox.Group
            className="[&_.input-layout.row]:flex-col [&_.input-layout.row]:gap-1 [&_.label]:mb-1"
            data-testid="usdc-access-form-form-factor"
            label={getLabel(t`forms.usdcAccessForm.formFactor`)}
            name="formFactor"
            onChange={handleChangeCheckboxGroup('formFactor')}
            value={selectedItems.formFactor}
          >
            {fieldItems.formFactor.map((field) => (
              <Checkbox key={field.label} {...field} />
            ))}
          </Checkbox.Group>

          {formState.errors.formFactor && requiredErrorMessage}
        </div>

        <Form.Input
          className="w-full"
          data-testid="usdc-access-form-questions"
          label={getLabel(t`forms.usdcAccessForm.questions`, false)}
          name="questions"
          type="text"
        />

        <p className="type-body-xs text-neutral-subtle">
          <Trans
            components={{
              0: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.userAgreement`}
                  type="external"
                />
              ),
              1: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.privacyPolicy`}
                  type="external"
                />
              ),
              2: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.acceptableUsePolicy`}
                  type="external"
                />
              ),
              3: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.cookiePolicy`}
                  type="external"
                />
              ),
              4: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.esignConsent`}
                  type="external"
                />
              ),
              5: (
                <Link
                  path={t`forms.usdcAccessForm.userAgreement.links.usdcRiskFactors`}
                  type="external"
                />
              ),
            }}
            i18nKey="common:forms.usdcAccessForm.userAgreement.text"
          />
        </p>

        <Form.SubmitButton
          data-testid="usdc-access-form-submit-button"
          loading={loading}
          variant="primary"
        >
          Submit
        </Form.SubmitButton>

        <p className="type-body-xs text-neutral-subtle">{t`forms.usdcAccessForm.disclaimer`}</p>
      </div>
    </Form>
  )
}

const SuccessMessage: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <div className="space-y-4">
      <CWIcon.Circle intent="success" name="CheckCircleSolid" size="large" />
      <h3 className="type-h-section-md text-neutral-strong">{t`forms.usdcAccessForm.submissionRecorded`}</h3>
    </div>
  )
}

/**
 * Generates a label for an input field, optionally including a required indicator
 * and typecasts it to a string to satisfy the input type constraints.
 * @param label - The input label.
 * @param required - Is the label required.
 * @returns The generated label as a React node.
 */
export const getLabel = (label: string, required: boolean = true) => {
  return (
    <span className="flex items-center justify-start h-7 text-neutral">
      {label}
      {required && (
        <span
          className="text-error inline-block ml-1"
          data-testid="required-indicator"
        >
          *
        </span>
      )}
    </span>
  ) as unknown as string
}

/**
 * Gets the options for a field from the locale file.
 * @param t - The input translation function.
 * @param name - The field name.
 * @returns The options for a field.
 */
const getItemsFromLocales = (t: Translate, name: string) => {
  const values: string[] = t(`forms.usdcAccessForm.${name}`, null, {
    returnObjects: true,
  })

  return values.map((value) => ({
    value,
    label: value,
  }))
}

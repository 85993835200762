'use client'

import type { FC } from 'react'

import { Table } from '@circlefin/components'

import type { TableProps } from '@circlefin/components/lib/Table'

export const CWTable = Table
export const CWTableHead = Table.Head
export const CWTableBody = Table.Body
export const CWTableHeadRow = Table.Head.Row
export const CWTableHeadCell = Table.Head.Cell
export const CWTableBodyRow = Table.Body.Row
export const CWTableBodyCell = Table.Body.Cell
export const CWTableBodyLoader = Table.Body.Loader

export const CWTableTight: FC<TableProps> = ({ children, ...props }) => (
  <div className="overflow-auto">
    <Table {...props} density="tight" layout="auto">
      {children}
    </Table>
  </div>
)

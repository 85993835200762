import type React from 'react'

import classNames from 'classnames'

import { Link, type LinkProps, CWButton } from '../../'

export interface HeroBannerContentProps {
  /**
   * Title.
   */
  title?: string
  /**
   * Description.
   */
  description?: string
  /**
   * CTA.
   */
  cta?: LinkProps & { label: string }
  /**
   * Custom style.
   */
  className?: string
}

export const HeroBannerContent: React.FC<HeroBannerContentProps> = ({
  title,
  description,
  cta,
  className,
}) => {
  return (
    <div className={classNames('flex flex-col gap-4 flex-1', className)}>
      {title && <h2 className="type-h-page-md text-neutral-strong">{title}</h2>}
      {description && (
        <p className="type-intro-sm text-neutral-strong">{description}</p>
      )}
      {cta && (
        <Link key={cta.label} className="w-fit" path={cta.path} type={cta.type}>
          <CWButton className="normal-case" variant="secondary">
            {cta.label}
          </CWButton>
        </Link>
      )}
    </div>
  )
}

import type { PropsWithChildren } from 'react'

import { type IconProps } from '@circlefin/components/lib/Icon'

import { CWIcon } from '../../ComponentsWeb'
import { FeatureDescription } from '../Description/Description'
import { FeatureTitle } from '../Title/Title'

export interface FeatureProps extends PropsWithChildren {
  /**
   * Icon that should be displayed next to the feature.
   */
  icon: IconProps['name']
}

interface Elements {
  Title: typeof FeatureTitle
  Description: typeof FeatureDescription
}

export const Feature: React.FC<FeatureProps> & Elements = ({
  icon,
  children,
}) => {
  return (
    <div className="flex items-start justify-start">
      <CWIcon
        className="mr-2 mt-1 h-5 w-5 shrink-0 text-blue-600"
        name={icon}
      />
      <div>{children}</div>
    </div>
  )
}

Feature.Title = FeatureTitle
Feature.Description = FeatureDescription

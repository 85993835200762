import type React from 'react'

import { CWChip } from '../ComponentsWeb'
import {
  OverviewCard,
  type OverviewCardProps,
} from '../OverviewCard/OverviewCard'

export interface ReleaseNoteCardProps
  extends Omit<OverviewCardProps, 'children'> {
  /**
   * Release type (chip content).
   */
  releaseType: string
  /**
   * Release date.
   */
  date: string
  /**
   * Card title.
   */
  title: string
  /**
   * Card description.
   */
  description: string
}

export const ReleaseNoteCard: React.FC<ReleaseNoteCardProps> = ({
  releaseType,
  date,
  title,
  description,
  ...cardProps
}) => (
  <OverviewCard className="flex flex-col" {...cardProps}>
    <div className="flex items-center justify-between">
      <CWChip variant="default/info">{releaseType}</CWChip>
      <span className="type-body-xs text-black">{date}</span>
    </div>
    <OverviewCard.Title className="mt-4" size="lg">
      {title}
    </OverviewCard.Title>
    <OverviewCard.Description className="mt-3 grow">
      {description}
    </OverviewCard.Description>
  </OverviewCard>
)
